<template>
    <div>
        <v-row>
            <v-col cols="12"><p class="cont-title">서비스 해지</p></v-col>
        </v-row>

        <v-card flat class="rounded-xl">
            <v-card-title class="pt-10 pl-10 pr-10" style="background:#EEEEEE; font-size:24px;">
                <v-icon color="red" large class="mr-3">mdi-alert</v-icon> 이번달 이용료 정산 후 서비스 해지가 완료됩니다.
            </v-card-title>
            <v-card-text class="pl-10 pr-10 pb-10" style="background:#EEEEEE; font-size:18px; line-height:2;">
                <ul>
                    <li>이번달 사용금액(일할 계산)이 등록된 결제정보를 통해 자동 결제됩니다. (미납금 포함)</li>
                </ul>
                <v-simple-table class="mt-5">
                    <thead>
                        <tr style="background-color: #b9b9b9;">
                            <th style="border-top: 1px solid; border-bottom: 1px solid; font-size: 18px;" class="text-center">콜리</th>
                            <th style="border-top: 1px solid; border-bottom: 1px solid; font-size: 18px;" class="text-center">이용기간</th>
                            <th style="border-top: 1px solid; border-bottom: 1px solid; font-size: 18px;" class="text-center">청구금액</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, idx) in billDetailList">
                            <tr :key="'bill_detail_' + idx + '_' + item.user_seqno">
                                <td class="text-center" style="font-size: 17px;">{{ item.user_name }}</td>
                                <td class="text-center" style="font-size: 17px;">{{ item.svc_start_date | dateOnly }} ~ {{ item.svc_end_date | dateOnly }}</td>
                                <td class="text-center" style="font-size: 17px;">{{ item.billing_amount | number }}</td>
                            </tr>
                        </template>
                        <template v-if="billDetailList && billDetailList.length != 0 && totalBillingAmount != 0">
                            <tr>
                                <td colspan="3" class="text-right" style="font-size: 17px;">총 결제금액 <span class="red--text font-weight-bold">{{ totalBillingAmount | number }}</span> 원</td>
                            </tr>
                        </template>
                        <template v-if="!billDetailList || billDetailList.length == 0 || totalBillingAmount == 0">
                            <tr>
                                <td colspan="3" class="text-center" style="font-size: 17px;">정산 금액이 없습니다.</td>
                            </tr>
                        </template>
                    </tbody>
                </v-simple-table>

                <v-divider class="mt-10"></v-divider>

                <ul class="mt-5">
                    <li>
                        해지하시는 이유를 알려주세요.
                    </li>
                </ul>

                <template>
                    <v-radio-group v-model="secedeReasonType" @change="setSecedeReasonType" class="ml-5">
                        <v-radio class="mt-1" key="SECEDE_REASON_01" :label="getSecedeReasonText('SECEDE_REASON_01')" value="SECEDE_REASON_01"></v-radio>
                        <v-radio class="mt-3" key="SECEDE_REASON_02" :label="getSecedeReasonText('SECEDE_REASON_02')" value="SECEDE_REASON_02"></v-radio>
                        <v-radio class="mt-3" key="SECEDE_REASON_03" :label="getSecedeReasonText('SECEDE_REASON_03')" value="SECEDE_REASON_03"></v-radio>
                        <v-radio class="mt-3" key="SECEDE_REASON_04" :label="getSecedeReasonText('SECEDE_REASON_04')" value="SECEDE_REASON_04"></v-radio>
                        <v-radio class="mt-3" key="SECEDE_REASON_05" :label="getSecedeReasonText('SECEDE_REASON_05')" value="SECEDE_REASON_05"></v-radio>
                        <v-radio class="mt-3" key="SECEDE_REASON_06" :label="getSecedeReasonText('SECEDE_REASON_06')" value="SECEDE_REASON_06"></v-radio>
                        <v-text-field
                            class="mt-2"
                            v-model="secedeReasonText"
                            outlined
                            dense
                            v-show="showSecedeReasonText"
                        ></v-text-field>
                    </v-radio-group>
                </template>
            </v-card-text>
        </v-card>

        <v-row>
            <v-col class="text-center">
                <v-btn style="font-size:20px; width:220px; height:50px; margin:20px;" color="grey" outlined @click="doCancel">취소</v-btn>
                <v-btn style="font-size:18px; width:220px; height:50px; margin:20px;" color="red" dark @click="prevConfirm">서비스 해지하기</v-btn>
            </v-col>
        </v-row>

        <v-card flat class="mt-16">
            <v-card-title class="pt-10 pl-10 pr-10" style="background:#EEEEEE; font-size:24px;">
               ※ 유의사항
            </v-card-title>
            <v-card-text class="pl-10 pr-10 pb-10" style="background:#EEEEEE; font-size:18px; line-height:2;">
                <ul>
                    <li>서비스 해지 후에는 관리자 페이지 접속 및 070번호, 통화내역, 녹취파일 등 이용정보가 즉시 삭제되어 복구가 불가합니다. 필요한 정보는 미리 백업해 주세요.</li>
                    <li>서비스 해지 완료 후 설치한 ‘콜’앱은 모두 로그아웃되어 전화의 수신/발신이 불가합니다.(통화중인 경우 통화가 끊길 수 있습니다.)</li>
                    <li>결제 실패 시 다른 결제 수단을 등록하여 결제성공 시에 탈퇴처리가 완료됩니다.</li>
                </ul>
            </v-card-text>
        </v-card>

        <v-dialog v-model="modal" persistent :max-width="popupMaxWidth">
            <v-flex v-if="dialogMode === 'result_bill'">
                <v-card hover>
                    <v-card-title style="height:70px; padding:30px; font: normal normal 600 25px/25px Apple SD Gothic Neo;">
                        <v-row>
                            <v-col>결제실패</v-col>
                            <v-col class="text-right pt-0" @click="closePopup"><v-btn icon><v-icon large>mdi-close</v-icon></v-btn></v-col>
                        </v-row>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text style="padding:30px 30px 20px 30px; font: normal normal normal 20px/22px Apple SD Gothic Neo; letter-spacing: -0.6px; color: #333333;" v-html="billMessage"></v-card-text>

                    <v-card-actions class="">
                        <v-row>
                            <v-col cols="12" class="pr-8 pb-5 pt-0 text-right">
                                <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large @click="goPayStatus"><span style="font: normal normal bold 18px/21px Apple SD Gothic Neo;">결제수단변경</span></v-btn>
                                <v-btn class="ma-2" width="130px" elevation="0"  color="#D3D8DD" outlined large @click="closePopup"><span style="font: normal normal bold 18px/21px Apple SD Gothic Neo; color:#D3D8DD">닫기</span></v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-dialog>

    </div>

</template>
<script>
import { mapState } from 'vuex'
import adminApi from '@/api/admin'

export default {
    name: 'admin_secede',
    components: {},
    data: () => ({
        secedeReasonType: 'SECEDE_REASON_01',
        secedeReasonText: '',
        showSecedeReasonText: false,
        popupMaxWidth: 600,
        modal: false,
        dialogMode: '',
        billMessage: ''
    }),
    created() {
        this.getBillDetailList()
    },
    computed: {
        ...mapState('admin', {
            billDetailList: state => state.billDetailList,
            totalBillingAmount: state => state.totalBillingAmount,
            prevBillMonth: state => state.prevBillMonth,
            prevBillMonthPaidYn: state => state.prevBillMonthPaidYn
        }),
    },
    mounted() {
    },
    methods: {
        getBillDetailList() {
            this.$store.dispatch('admin/getBillDetailList', {
                param: {},
                scb: (va) => {},
                fcb: (err, va) => {
                    va.$store.dispatch('openAlert', {
                        'message': err,
                        'va': va
                    })
                    console.log('failed to getBIllDetailList: err', err)
                },
                va: this
            })
        },
        doCancel() {
            this.$router.push('/admin/info')
        },
        prevConfirm() {

            if (this.secedeReasonType == 'SECEDE_REASON_06' && this.secedeReasonText.trim() == '') {
                this.$store.dispatch('openAlert', {message: '해지 사유를 입력해 주세요',})
                return
            }

            let ctx = this
            this.$store.dispatch('openConfirm', {
                message: '서비스 해지 시 이용료가 즉시 결제되고 정상 결제 시 해지가 완료됩니다.',
                acceptText: '해지하기',
                va: this,
                okCb: () => {
                    ctx.doSecede()
                }
            })
        },
        setSecedeReasonType() {
            if (this.secedeReasonType == 'SECEDE_REASON_06') {
                this.showSecedeReasonText = true
            } else {
                this.showSecedeReasonText = false
            }
        },
        doSecede() {
            let param = {}

            param.reason_text = this.secedeReasonType == 'SECEDE_REASON_06' ? this.secedeReasonText : this.getSecedeReasonText(this.secedeReasonType)
            param.bill_detail_list = this.billDetailList
            param.prev_bill_month = this.prevBillMonth
            param.prev_bill_month_paid_yn = this.prevBillMonthPaidYn

            let scb = (va) => {
                va.$store.dispatch('openAlert', {
                    message: '서비스 해지가 완료되었습니다.\n이용해주셔서 감사합니다.',
                    closeCb: (va) => {
                        this.$store.dispatch('login/LOGOUT', {serverFlag: true}).then(() => {
                            this.$router.push('/login')
                        })
                    }, va: va
                })

            }

            let fcb = (err, va) => {

                if (err.indexOf('BILL_FAIL') != -1) {
                    err = err.replace('BILL_FAIL|', '')
                    va.showBillFailPopup(err)
                } else {
                    va.$store.dispatch('openAlert', {
                        message: `처리 중 오류가 발생하였습니다. (${err})`,
                        closeCb: (va) => {

                        }, va: va
                    })
                }
            }

            adminApi.requestSecede(param, scb, fcb, this)
        },
        showBillFailPopup(msg) {
            this.billMessage = `${msg}`
            this.modal = true
            this.dialogMode = 'result_bill'
        },
        closePopup() {
            if (this.modal == true && this.dialogMode == 'failed_bill') {
                this.billMessage = ''
            }

            this.modal = false
            this.dialogMode = ''
        },
        goPayStatus() {
            this.$router.push('/pay/status')
        },
        getSecedeReasonText(v) {
            let ret = ''

            switch (v) {
                case 'SECEDE_REASON_01':
                    ret = '사용빈도가 낮아서'
                    break
                case 'SECEDE_REASON_02':
                    ret = '서비스 이용이 불편해서'
                    break
                case 'SECEDE_REASON_03':
                    ret = '원하는 기능이 없어서'
                    break
                case 'SECEDE_REASON_04':
                    ret = '타 서비스로 이동'
                    break
                case 'SECEDE_REASON_05':
                    ret = '서비스 이용료가 비싸서'
                    break
                case 'SECEDE_REASON_06':
                    ret = '직접입력'
                    break
            }

            return ret
        }
    }
}
</script>
